.temp-navbar {
  padding: 0.8rem 0;
}

#top-menu-float {
  position: absolute;
  top: -4.5rem;
}

@media(min-width: 1240px) {
  #top-menu-float.is-sticky {
    position: fixed !important;
    padding: 0.2rem 0;
    top: -4.5rem;
    transition: top 0.3s;
  }
}

// @media(max-width: 1240px) {
//   #top-menu {
//     left: 0;
//     position: fixed;
//     top: 0;
//     right: 0;
//     z-index: 30;
//   }
// }

.section.nossas-solucoes {
  background-color: #e8edf1;
}

.section.nossos-planos {
  background-color: #f5f6f6;
}

.section.clientes-satisfeitos {
  background-color: #e8edf1;
}

.section.nossa-historia {
  background-color: #f5f6f6;
}

.section.atendimento {
  background-color: #e8edf1;
}

.footer.todos-direitos-reservados {
    color: #E9E9E9;
    font-size: 1rem;
    line-height: 22px;
}

.navbar.has-shadow {
  box-shadow: 0 1px 0 0 whitesmoke;
}

#nossas-solucoes, #nossos-planos, #clientes-satisfeitos, #nossa-historia, #atendimento {
  scroll-margin-top: 2rem;
}

#home {
  scroll-margin-top: 6rem;
}

a.back-to-top, 
a.back-to-top:hover, 
a.back-to-top:visited, 
a.back-to-top:focus {
  color: #E9E9E9;
  text-decoration: none;
  outline: 0;
}
